import {
  CountFreightsQuery,
  CountFreightsQueryHandler,
  CountResponse,
  DocumentMetadataResponse,
  DownloadCompanyDocumentQuery,
  DownloadCompanyDocumentQueryHandler,
  DownloadDocumentResponse,
  FindFreightQuery,
  FindFreightQueryHandler,
  FindMarketFreightQuery,
  FindMarketFreightQueryHandler,
  FreightBasicResponse,
  FreightDetailedResponse,
  FreightMarketResponse,
  GenerateAndDownloadEcmrQuery,
  GenerateAndDownloadEcmrQueryHandler,
  GenericDownloadDocumentQuery,
  GenericDownloadDocumentQueryHandler,
  InvoiceResponse,
  QueryRepository,
  SearchCarrierDocumentMetadataQuery,
  SearchCarrierDocumentMetadataQueryHandler,
  SearchDocumentMetadataQuery,
  SearchDocumentMetadataQueryHandler,
  SearchFreightsWithPendingDocumentationQuery,
  SearchFreightsWithPendingDocumentationQueryHandler,
  SearchInProgressFreightsQuery,
  SearchInProgressFreightsQueryHandler,
  SearchInvoicesQuery,
  SearchInvoicesQueryHandler,
  SearchMarketFreightsQuery,
  SearchMarketFreightsQueryHandler,
  SearchNotInProgressFreightsQuery,
  SearchNotInProgressFreightsQueryHandler,
} from '@okcargo/query-processor';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { BaseQueryRepository } from 'src/app/common/infrastructure/query.repository';

@Injectable({
  providedIn: 'root',
})
export class FreightQueryService {
  private countFreightsQueryHandler: CountFreightsQueryHandler;
  private searchInProgressFreightsQueryHandler: SearchInProgressFreightsQueryHandler;
  private searchNotInProgressFreightsQueryHandler: SearchNotInProgressFreightsQueryHandler;
  private findFreightQueryHandler: FindFreightQueryHandler;
  // private countMarketFreightsQueryHandler: CountMarketFreightsQueryHandler;
  private searchMarketFreightsQueryHandler: SearchMarketFreightsQueryHandler;
  private findMarketFreightQueryHandler: FindMarketFreightQueryHandler;
  private generateAndDownloadEcmrQueryHandler: GenerateAndDownloadEcmrQueryHandler;
  private searchDocumentMetadataQueryHandler: SearchDocumentMetadataQueryHandler;
  private searchFreightsWithPendingDocumentationQueryHandler: SearchFreightsWithPendingDocumentationQueryHandler;
  private genericDownloadDocumentQueryHandler: GenericDownloadDocumentQueryHandler;
  private downloadCompanyDocumentQueryHandler: DownloadCompanyDocumentQueryHandler;

  private searchCarrierDocumentMetadataQueryHandler: SearchCarrierDocumentMetadataQueryHandler;
  private searchInvoicesQueryHandler: SearchInvoicesQueryHandler;

  constructor(private queryRepository: BaseQueryRepository) {
    this.countFreightsQueryHandler = new CountFreightsQueryHandler(
      queryRepository
    );
    this.searchInProgressFreightsQueryHandler =
      new SearchInProgressFreightsQueryHandler(queryRepository);
    this.searchNotInProgressFreightsQueryHandler =
      new SearchNotInProgressFreightsQueryHandler(queryRepository);
    this.findFreightQueryHandler = new FindFreightQueryHandler(queryRepository);
    this.searchMarketFreightsQueryHandler =
      new SearchMarketFreightsQueryHandler(queryRepository);
    this.generateAndDownloadEcmrQueryHandler =
      new GenerateAndDownloadEcmrQueryHandler(queryRepository);
    this.searchDocumentMetadataQueryHandler =
      new SearchDocumentMetadataQueryHandler(queryRepository);
    this.findMarketFreightQueryHandler =
      new FindMarketFreightQueryHandler(queryRepository);
    this.searchFreightsWithPendingDocumentationQueryHandler =
      new SearchFreightsWithPendingDocumentationQueryHandler(queryRepository);
    this.genericDownloadDocumentQueryHandler =
      new GenericDownloadDocumentQueryHandler(queryRepository);
    this.downloadCompanyDocumentQueryHandler =
      new DownloadCompanyDocumentQueryHandler(queryRepository);

    this.searchCarrierDocumentMetadataQueryHandler =
      new SearchCarrierDocumentMetadataQueryHandler(queryRepository);

    this.searchInvoicesQueryHandler =
      new SearchInvoicesQueryHandler(queryRepository);
  }

  countFreights(query: CountFreightsQuery): Promise<CountResponse> {
    return this.countFreightsQueryHandler.handle(query);
  }

  searchInProgressFreights(
    query: SearchInProgressFreightsQuery
  ): Promise<FreightBasicResponse[]> {
    return this.searchInProgressFreightsQueryHandler.handle(query);
  }

  searchFinishedFreights(
    query: SearchNotInProgressFreightsQuery
  ): Promise<FreightBasicResponse[]> {
    return this.searchNotInProgressFreightsQueryHandler.handle(query);
  }

  searchFreightsWithPendingDocumentation(
    query: SearchFreightsWithPendingDocumentationQuery
  ): Promise<FreightBasicResponse[]> {
    return this.searchFreightsWithPendingDocumentationQueryHandler.handle(
      query
    );
  }

  findFreight(query: FindFreightQuery): Observable<FreightDetailedResponse> {
    return from(this.findFreightQueryHandler.handle(query));
  }

  /*countMarketFreights(query: CountMarketFreightsQuery): Promise<CountResponse> {
    return this.countMarketFreightsQueryHandler.handle(query);
  }*/

  searchMarketFreights(
    query: SearchMarketFreightsQuery
  ): Promise<FreightMarketResponse[]> {
    return this.searchMarketFreightsQueryHandler.handle(query);
  }

  findFreightFromMarket(
    query: FindMarketFreightQuery
  ): Observable<FreightMarketResponse> {
    return from(this.findMarketFreightQueryHandler.handle(query));
  }

  generateAndDownloadEcmr(
    query: GenerateAndDownloadEcmrQuery
  ): Promise<DownloadDocumentResponse> {
    return this.generateAndDownloadEcmrQueryHandler.handle(query);
  }

  searchDocumentMetadata(
    query: SearchDocumentMetadataQuery
  ): Promise<DocumentMetadataResponse[]> {
    return this.searchDocumentMetadataQueryHandler.handle(query);
  }

  downloadGenericDocument(
    query: GenericDownloadDocumentQuery
  ): Promise<DownloadDocumentResponse> {
    return this.genericDownloadDocumentQueryHandler.handle(query);
  }

  downloadCompanyDocument(
    query: DownloadCompanyDocumentQuery
  ): Promise<DownloadDocumentResponse> {
    return this.downloadCompanyDocumentQueryHandler.handle(query);
  }

  searchCarrierDocumentMetadata(
    query: SearchCarrierDocumentMetadataQuery
  ): Promise<DocumentMetadataResponse[]> {
    return this.searchCarrierDocumentMetadataQueryHandler.handle(query);
  }

  searchInvoices(
    query: SearchInvoicesQuery
  ): Promise<InvoiceResponse[]> {
    return this.searchInvoicesQueryHandler.handle(query);
  }
}
