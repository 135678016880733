import { Injectable } from '@angular/core';
import { Query, QueryRepository } from '@okcargo/query-processor';
import { ApiService } from './api.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BaseQueryRepository implements QueryRepository {
  private _resourceUrl = '/services/query-processor/api/queries';

  constructor(private apiService: ApiService) {}

  execute(name: string, query: Query): Promise<any> {
    return firstValueFrom(
      // this.apiService.get<any>(
      this.apiService.get(
        `${this._resourceUrl}?name=${name}&payload=${encodeURI(
          JSON.stringify(query)
        )}`,
        { observe: 'body' },
      )
    );
  }
}
